<template>
  <div
    class="d-flex text-truncate py-75 px-1 px-md-2 rounded-8px w-100 gap-2"
    :class="{'bg-gray ': showInfoFlight}"
  >
    <div
      v-if="showInfoFlight"
      class="flex-fill"
    >
      <div
        v-if="flightInfo.status"
        class="pb-25 d-flex-between"
      >
        <small>{{ $t('reservation.flightsDetails.status') }}: </small>
        <div class="d-flex gap-2">
          <span
            class="fw-700"
            :class="flightInfo.status === 'HK' ? 'text-success' : (['HL', 'SC', 'WK'].includes(flightInfo.status) ? 'text-danger' : (flightInfo.status === 'KL' ? 'text-warning' : ''))"
          >
            {{ flightInfo.status }}
          </span>
          <div
            v-if="['UC','HL','DS','NO','HX','AK','GK'].includes(flightInfo.status)"
            class="text-danger fw-700"
          >
            Lưu ý: Chưa có chỗ
          </div>
        </div>
      </div>

      <div
        v-if="flightInfo.bookingClass || flightInfo.groupClass"
        class="pb-25 d-flex-between"
      >
        <small>{{ $t('reservation.flightsDetails.bookingClass') }}: </small>
        <div v-if="flightInfo.fareBasisCode">
          (<span class="font-weight-bold">
            <span v-if="reservationsData.source === 'VU' && flightInfo.fareType">
              {{ flightInfo.fareType }}
            </span>
            <span v-else>{{ flightInfo.groupClass || fareRuleAirlineByBookingClass?.cabinName }}</span>
          </span>
          <span v-if="flightInfo.bookingClass && (flightInfo.groupClass || fareRuleAirlineByBookingClass?.cabinName)">
            |
          </span>
          <span class="font-weight-bold">
            {{ flightInfo.bookingClass }}
          </span>

          <span class="fw-600 text-danger ml-25">
            {{ flightInfo.fareBasisCode }}
          </span>
          )
        </div>

        <div v-else>
          <span class="font-weight-bold">
            {{ flightInfo.groupClass || fareRuleAirlineByBookingClass?.cabinName }}
          </span>
          <span v-if="flightInfo.bookingClass && (flightInfo.groupClass || fareRuleAirlineByBookingClass?.cabinName)">
            |
          </span>
          <span class="font-weight-bold">
            {{ flightInfo.bookingClass }}
          </span>
        </div>
      </div>

      <div
        v-if="reservationsData.supplierPNRs && reservationsData.supplierPNRs.length"
        class="pb-25 d-flex-between"
      >
        <small>{{ $t('reservation.airPnr') }}: </small>
        <span class="font-weight-bold">
          {{ resolveAirPnr(reservationsData.supplierPNRs, flightInfo.airline, { showInEticket: false }) }}
        </span>
      </div>

      <!-- NOTE - Text Hoàn vé -->
      <div
        v-if="
          (reservationsData.isDomestic && (flightInfo.bookingClass || flightInfo.groupClass) && reservationsData.source !== 'QH')
            || (reservationsData.isDomestic && flightInfo.groupClass && reservationsData.source === 'QH')
            || (['VU'].includes(reservationsData.source))
        "
        :id="`rule-refund-${index}`"
        class="pb-25 d-flex-between"
      >
        <small>{{ $t(`reservation.${reservationsData.source === 'VJ' ? 'refundTicketVJ' : 'refundTicket'}`) }}: </small>
        <BBadge
          :variant="['', 'NONE', false].includes(fareRuleAirlineByBookingClass?.refund) ? 'danger' : 'info'"
          class="font-weight-bold text-wrap"
        >
          {{
            ['', 'NONE', false].includes(fareRuleAirlineByBookingClass?.refund)
              ? $t('reservation.disallowRefund')
              : $t('reservation.allowRefund')
          }}
          {{ `${reservationsData.source === 'VJ' ? $t('reservation.identification') : ''}` }}
        </BBadge>
      </div>

      <div
        v-if="flightInfo.mileage"
        class="pb-25 d-flex-between"
      >
        <small>{{ $t('reservation.flightsDetails.distance') }}: </small>
        <span class="font-weight-bold">{{
          Number(flightInfo.mileage)
        }}</span>
      </div>

      <!-- NOTE Text hanh ly mien cuoc -->
      <!-- NOTE Text XACH TAY -->
      <div
        v-b-tooltip.hover
        class="text-wrap pb-25 d-flex-between"
        :title="baggageInBookingDetail?.carryBag"
      >
        <small class="text-nowrap">
          {{ $t('ticket.flightsInfo.handBaggage') }}:
        </small>
        <span
          v-if="((reservationsData.source !== 'QH')
            || (reservationsData.source === 'QH' && flightInfo?.groupClass))
            && baggageInBookingDetail?.carryBag"
          class="font-weight-bold"
        >
          {{ baggageInBookingDetail?.carryBag }}
        </span>
        <small
          v-else
          v-b-tooltip.hover.window="$t('reservation.contactBookerBaggage')"
          class="font-weight-bold text-danger text-truncate width-200"
        >
          {{ $t('reservation.contactBookerBaggage') }}
        </small>
      </div>

      <!-- NOTE Text KY GUI -->
      <div
        v-if="(reservationsData.source !== 'QH') || (reservationsData.source === 'QH' && flightInfo?.groupClass)"
        v-b-tooltip.hover
        :title="baggageInBookingDetail?.checkinBag"
        class="text-wrap d-flex-between"
      >
        <small>{{ $t('ticket.flightsInfo.freeBaggage') }}: </small>
        <span class="font-weight-bold">
          {{ baggageInBookingDetail?.checkinBag }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="flex-fill"
    />
    <div>
      <BButton
        v-if="!hideFeature"
        variant="primary"
        class="p-25 rounded-circle"
        @click="showInfoFlight = !showInfoFlight"
      >
        <feather-icon
          v-if="showInfoFlight"
          icon="EyeIcon"
          size="16"
        />
        <feather-icon
          v-else
          icon="EyeOffIcon"
          size="16"
        />
      </BButton>
    </div>
  </div>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
import { BButton, BBadge, VBTooltip } from 'bootstrap-vue'

import VueI18n from '@/libs/i18n'

import useReservationHandle, { resolveAirPnr } from '@reservation/useReservationHandle'

export default {
  components: {
    BButton, BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    flightInfo: {
      type: Object,
      default: () => {},
    },
    reservationsData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const {
      getBaggageInBookingDetail,
      getFareRulesAirlineByBookingClass,
      hideFeature,
    } = useReservationHandle()

    const fareRuleAirlineByBookingClass = computed(() => {
      const res = getFareRulesAirlineByBookingClass(
        props.flightInfo.airline,
        ['VJ', 'QH'].includes(props.flightInfo.airline)
          ? props.flightInfo.groupClass
          : ['VU'].includes(props.flightInfo.airline)
            ? props.flightInfo.fareType : props.flightInfo.bookingClass,
      )
      return res
    })

    const locale = computed(() => VueI18n.locale).value
    const baggageInBookingDetail = computed(() => {
      const resBaggage = getBaggageInBookingDetail(props.flightInfo, props.reservationsData, locale)
      return resBaggage
    })
    const showInfoFlight = ref(true)
    return {
      baggageInBookingDetail,
      fareRuleAirlineByBookingClass,
      resolveAirPnr,
      showInfoFlight,
      hideFeature,
    }
  },
}
</script>
<style lang="">

</style>
